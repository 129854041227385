import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import camelCase from 'lodash/camelCase'
import { media, spacing, tokens } from '@tokens'
import SeriesBadge from '@components/Series/SeriesBadge'
import Image from '@elements/Image'
import { H3 } from '@styles/Typography'
import { motion } from 'framer-motion'
import { MagicItem } from '@hooks/useMagic'

export const BeerTeaser = styled(motion.div)`
  width: 100%;

  .beer-teaser__title {
    margin-top: ${({ teaserVariant }) =>
      teaserVariant === 'box' ? spacing(0) : spacing(3)};
    text-align: center;

    ${media.lessThan('s')`
      font-size: 1.125rem;
    `}

    ${media.lessThan('360px')`
      font-size: 1rem;
    `}
    margin-bottom: 0;
  }

  .beer-teaser__image,
  .image {
    position: relative;
    width: 100%;
    max-width: none;
  }

  ${styledMap('teaserVariant', {
    artwork: css`
      .beer-teaser__image {
        box-shadow: ${tokens.get('shadows.beerLabel')};
        background: ${tokens.color('text')};
      }
    `,
    default: '',
  })};

  .beer-teaser__badge {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .beer-teaser__abv {
    text-align: center;
    opacity: 0.5;
    display: block;
    width: 100%;
    padding-top: 1rem;
    margin-top:auto;
    margin-bottom: 0;
  }

  ${({ teaserVariant, featureImageType }) => {
    if (teaserVariant) return

    return css`
      .gatsby-image-wrapper {
        > div:first-child {
          padding-bottom: ${tokens.get('beerTeaserRatio')[
            featureImageType
          ]} !important;
        }
      }
    `
  }}
`

export default ({
  teaserVariant,
  featureImageType = 'large-bottle',
  labelArtwork,
  image,
  title,
  icon,
  className,
  rowClasses = [],
  hasABV,
  ...beer
}) => {
  const [isHovered, setIsHovered] = useState(false)
  let beerImage
  switch(teaserVariant) {
    case 'artwork':
      beerImage = labelArtwork
      break;
    case 'box':
      beerImage = image
      break;
    default:
      beerImage = beer[`${camelCase(featureImageType)}Image`]
      break;
  }
  
  const beerImageClasses = [
    'beer-teaser__image',
    teaserVariant ? ` beer-teaser__image--${teaserVariant}` : '',
    ...rowClasses,
  ].join(' ')

  return (
    <BeerTeaser
      className={className}
      teaserVariant={teaserVariant}
      featureImageType={featureImageType}
      whileHover="hovered"
      hasABV={hasABV}
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    > 
      <MagicItem type="fadeUp" whileHover="hovered">
        <div className={beerImageClasses}>
          {teaserVariant === "artwork"
            ? beerImage && (
                <Image
                  key={beerImage.localFile.publicURL}
                  source={beerImage}
                  className="artwork-image"
                />
              )
            : beerImage && (
                <motion.div
                  animate={isHovered ? 'hovered' : 'init'}
                  variants={{
                    init: {
                      y: 0,
                    },
                    hovered: {
                      y: -16,
                      transition: {
                        ease: 'easeOut',
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <Image
                    key={beerImage.localFile.publicURL}
                    source={beerImage}
                    objectFit="contain"
                  />
                </motion.div>
              )}
          {icon && (
            <SeriesBadge className="beer-teaser__badge">
              <Image source={icon} alt_text={`${title} icon`} />
            </SeriesBadge>
          )}
        </div>
        <H3
          className="beer-teaser__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </MagicItem>
        {hasABV && <span className='beer-teaser__abv'>{beer?.abv}% ABV</span>}
    </BeerTeaser>
  )
}
