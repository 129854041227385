/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import ComponentContainer from '@layout/ComponentContainer'
import TeaserSeries from '@components/Teasers/TeaserSeries'
import BeerIntroText from '@components/Beer/BeerIntroText'
import BeerAwards from '@components/Beer/BeerAwards'
import BeerDetails from '@components/Beer/BeerDetails'
import BeerGlassImage from '@components/Beer/BeerGlassImage'
import BeerIngredients from '@components/Beer/BeerIngredients'
import BeerFormatGallery from '@components/Beer/BeerFormatGallery'
import TeaserListRow from '@components/TeasersList/TeaserListRow'
import SeriesSlider from '@components/Series/SeriesSlider'
import Quote from '@components/Quote'
import { formatTemplateData } from '@utils/templates'
import { ComponentSpacer } from '@layout/FlexComponents'
import lionBadge from '@images/lionBadge.svg'

export const BeerTemplate = ({
  data: {
    wpBeer: { contentBeer, sizeFormats, uri, seo, ...beerData },
    allWpBeer: { nodes: relatedBeers },
  },
}) => {
  seo.path = uri
  const { id, title, slug } = beerData
  const [seriesData] = beerData.allSeries.nodes.some(
    ({ slug }) => slug === 'young-lions'
  )
    ? beerData?.allSeries?.nodes.filter(({ slug }) => {
        return slug === 'young-lions'
      })
    : beerData?.allSeries?.nodes

  const {
    introContent,
    awards,
    shortDescription,
    abv,
    availability,
    maltSlider,
    hopsSlider,
    featureImageType,
    relatedPosts,
    glassImage,
    gallery,
    tasteDescription,
    malts,
    hops,
    quote,
    heroImage,
    ...beer
  } = contentBeer

  const template = {
    activeSlug: 'beers',
    id,
    slug,
    beerImageType: featureImageType || 'large-bottle',
    featureImageType,
    heroFields: {
      image: heroImage,
      textContent: {
        heading: contentBeer.heroHeading,
      },
      gallery,
    },
    title,
    designations: 'isSingleBeerPage',
    heroType: 'singleBeer',
    theme: 'dark',
    menuTheme: 'dark',
    seo,
    ...beer,
  }

  const introTextProps = {
    textContent: {
      selectedFields: ['heading', 'content'],
      heading: title,
      content: introContent,
    },
    contentAlign: 'right',
    textAlign: 'left',
    size: 'wide',
  }

  const seriesTeaser = {
    textContent: seriesData?.contentSeries?.textContent || {},
    name: seriesData.name,
    description: seriesData.description,
    icon: seriesData?.contentSeries?.icon || {
      localFile: {
        extenstion: 'svg',
        publicURL: lionBadge,
      },
    },
    slug: seriesData.slug,
  }

  const beerDetailProps = {
    details: {
      type: {
        label: 'Beer Type',
        value: shortDescription,
      },
      abv: {
        label: 'ABV',
        value: abv,
      },
      availability: {
        label: 'Availability',
        value: availability,
      },
      malt: {
        label: 'Malt',
        value: maltSlider,
      },
      hops: {
        label: 'Hops',
        value: hopsSlider,
      },
    },
    formats: {
      label: 'Available Formats',
      sizes: sizeFormats,
    },
  }

  const beerGlassProps = {
    glassImage,
    tasteDescription,
  }

  const ingredientsProps = {
    malts,
    hops,
  }

  const articleProps = {
    heading: `Learn More About ${beerData.title}`,
    headingSize: 'shortLarge',
    teasers: relatedPosts
      ? relatedPosts.map(
          ({ id, title: postTitle, uri, topCategory, eventDate }) => {
            return {
              id,
              uri,
              title: postTitle,
              date: eventDate,
              topCategory,
            }
          }
        )
      : null,
  }

  const relatedBeerProps = {
    heading: `More Hoyne ${seriesData.name} Beers`,
    beers: relatedBeers,
  }

  return (
    <>
      <Container template={formatTemplateData(template)}>
        <BeerIntroText {...introTextProps} />
        {awards && <BeerAwards awards={awards}/>}
        <BeerFormatGallery {...beer} />
        {seriesTeaser.slug === 'young-lions' && (
          <ComponentContainer>
            <TeaserSeries {...seriesTeaser} />
          </ComponentContainer>
        )}
        <ComponentContainer>
          <BeerDetails {...beerDetailProps} />
        </ComponentContainer>
        <ComponentContainer size="wide">
          <BeerGlassImage {...beerGlassProps} />
          <BeerIngredients {...ingredientsProps} />
        </ComponentContainer>
        <Quote {...quote} />
        {articleProps.teasers && (
          <ComponentContainer>
            <TeaserListRow {...articleProps} />
          </ComponentContainer>
        )}
        {relatedBeerProps.beers.length ? (
          <SeriesSlider {...relatedBeerProps} />
        ) : (
          <ComponentSpacer />
        )}
      </Container>
    </>
  )
}

BeerTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BeerTemplate

export const pageQuery = graphql`
  fragment BeerFields on WpBeer {
    id
    title
    slug
    allSeries {
      nodes {
        slug
        name
        description
        contentSeries {
          textContent {
            selectedFields
            heading
            content
            link {
              data {
                url
                title
                target
              }
              type
              text
            }
          }
          icon {
            localFile {
              extension
              publicURL
            }
          }
        }
      }
    }
    contentBeer {
      relatedPosts {
        ... on WpPost {
          ...TeaserPostFields
        }
      }
      awards {
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 280, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      tasteDescription
      maltSlider
      hopsSlider
      heroHeading
      introContent
      shortDescription
      availability
      featureImageType
      abv
      relatedBeerOption
      quote {
        content
        authorMeta
        author
      }
      malts {
        slug
        name
      }
      hops {
        slug
        name
      }
      series {
        name
        slug
      }
      heroImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2550, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      glassImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 281, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      gallery {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      smallBottleImage {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      largeBottleImage {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      smallCanImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      largeCanImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      smallBottleBoxImage {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      largeBottleBoxImage {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      smallCanBoxImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      largeCanBoxImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    sizeFormats {
      nodes {
        slug
        name
        contentSizeFormats {
          label
          icon {
            localFile {
              childImageSharp {
                fluid(maxWidth: 100, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
  query BeerByID($id: String!, $seriesSlug: String!) {
    wpBeer(id: { eq: $id }) {
      ...BeerFields
      uri
      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
    allWpBeer(
      filter: {
        allSeries: { nodes: { elemMatch: { slug: { eq: $seriesSlug } } } }
        id: { ne: $id }
      }
    ) {
      nodes {
        id
        title
        uri
        contentBeer {
          featureImageType
          smallBottleImage {
            altText
            localFile {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 560, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          largeBottleImage {
            altText
            localFile {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 560, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          smallCanImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 560, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          largeCanImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 560, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
