import React from 'react'
import { graphql } from 'gatsby'
import ComponentContent from '@blocks/ComponentContent'
import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import styledMap from 'styled-map'
import Text from '@components/Text'
import { LargeTypeSizes } from '@styles/Typography'
import { ComponentBase } from '@mixins/MixinsComponent'

export const BeerIntroText = styled.div`
  ${ComponentBase}
  width: 100%;

  ${media.lessThan('1199px')`
    margin-top: 0;
  `}

  .inner {
    ${media.greaterThan('l')`
      margin-top: ${spacing(-2)};
      padding-left: 12rem;
    `}
  }

  .introContent {
    ${media.greaterThan('l')`
      margin-right: 0;
    `}
  }

  p {
    ${LargeTypeSizes}
  }
`

export default ({ textContent, className = '', size }) => {
  return (
    <BeerIntroText className={className} size={size}>
      <div className="inner">
        <ComponentContent
          className="introContent"
          textContent={textContent}
          contentAlign="center"
          headingSize="shortLarge"
          size="narrow"
        />
      </div>
    </BeerIntroText>
  )
}
