import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Heading from '@elements/Heading'
import TeaserBeer from '@components/Teasers/TeaserBeer'
import Link from '@elements/Link'

export const SeriesSlider = styled.div`
  ${box({
    spacing: 'x',
  })};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  > .inner {
    max-width: ${tokens.get('maxWidth.wide')};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: -6rem;

    ${media.greaterThan('s')`
      margin-bottom: unset;
    `}
  }

  .beers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    width: auto;
  }

  .heading {
    ${box({
      spacing: 'bottom',
      multiplier: 0.5,
    })};
    margin-bottom: 0;
    flex: 1 1 100%;
    max-width: none;
    text-align: center;
  }

  .beer-link {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
  }

  .beer-teaser {
    > div > div {
      margin-top: ${spacing(5)};
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }

    .beer-teaser__image {
      order: 2;
    }

    .beer-teaser__title {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      text-transform: none;
      font-weight: ${tokens.get('type.fontWeight.normal')};
      margin-bottom: ${spacing()};
    }
  }



  ${({ teaserVariant }) => {
    if(teaserVariant === 'box') {   
      return css`

        .beers {
          
          margin-bottom: ${spacing(8)};
          
          ${media.greaterThan('s')`
              margin-bottom: ${spacing(-6)};
          `}

          ${media.greaterThan('m')`
              margin-bottom: ${spacing(-8)};
          `}
        }

        .beer-link {
          margin-left: ${spacing(2)};
          margin-right: ${spacing(2)};
          width: 50%;
          
          &.beer-link--4-large-can {
            max-width: 35%;
          }
        }
      `
    }
    
    
    return css`

      .beers {
        margin-bottom: ${spacing(-12)};
      }

      .beer-link {
        margin-left: ${spacing(2)};
        margin-right: ${spacing(2)};
        width: calc((100% - ${spacing(8)}) / 2);
    
        ${media.greaterThan('s')`
          width: calc((100% - ${spacing(12)}) / 4);
        `}
    
        ${media.greaterThan('m')`
          width: calc((100% - ${spacing(18)}) / 4);
          margin-left: ${spacing(3)};
          margin-right: ${spacing(3)}
        `}
    
        ${media.lessThan('s')`
          &:nth-child(n + 3) {
            display: none;
          }
        `}
      }
    `
  }}

`

export default ({ heading, beers: initalBeers, teaserVariant, number = 4 }) => {
  const beers = initalBeers.slice(Math.max(initalBeers.length - number, 0))

  return (
    <SeriesSlider size="wide" teaserVariant={teaserVariant}>
      <Heading headingSize="shortLarge" className="heading">
        {heading}
      </Heading>
      <div className="inner">
        <div className="beers">
          {beers &&
            beers.map(({ uri, title, contentBeer }) => {
              const className = `beer-link beer-link--${contentBeer?.featureImageType}`
              return (
                <Link
                  key={title}
                  className={className}
                  link={{ url: uri }}
                  linkStyle="none"
                >
                  <TeaserBeer
                    path={uri}
                    title={title}
                    className="beer-teaser"
                    teaserVariant={teaserVariant}
                    {...contentBeer}
                  />
                </Link>
              )
            })}
        </div>
      </div>
    </SeriesSlider>
  )
}
