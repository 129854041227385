import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import Image from '@elements/Image'
import SeriesBadge from '@components/Series/SeriesBadge'
import { H3 } from '@styles/Typography'
import ComponentContent from '@blocks/ComponentContent'
import TextContent from '@elements/TextContent'
import Link from '@elements/Link'

export const SeriesTeaser = styled.div`
  max-width: ${tokens.get('maxWidth.base')};
  margin-left: auto;
  margin-right: auto;
  padding: ${spacing(5)} ${spacing(3)};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-radius: ${spacing()};
  border: 1px solid
    ${styledMap('theme', {
      dark: tokens.color('white', '03'),
      default: tokens.color('black', '03'),
    })};

  ${media.greaterThan('s')`
    flex-flow: row nowrap;
    align-items: flex-start;
    padding-top: ${spacing(6)};
    padding-bottom: ${spacing(6)};
  `}

  .inner {
    flex: 1 1 auto;
  }

  .content-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    ${media.greaterThan('s')`
      justify-content: flex-start;
      align-items: flex-start;
    `}
  }

  .content {
    max-width: 24em;
  }

  .heading,
  .content {
    ${media.lessThan('s')`
      text-align: center;
    `}
  }

  .icon-container {
    flex: 0 0 12.5rem;

    ${media.greaterThan('s')`
      padding-right: ${spacing(5)};
      padding-left: ${spacing(3)};
    `}

    ${media.greaterThan('l')`
      flex-basis: 16.25rem;
    `}
  }

  .badge {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${spacing(5)};
    height: 8rem;
    width: 8rem;

    ${media.greaterThan('s')`
      margin-right: 0;
      margin-bottom: 0;
    `}

    img {
      width: 100%;
      height: auto;
    }
  }

  .teaser-link {
    margin-top: ${spacing(4)};

    ${media.lessThan('s')`
      margin-left: auto;
      margin-right: auto;
    `}
  }
`

export default ({
  icon,
  name,
  textContent: { heading, content, link },
  description,
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <SeriesTeaser theme={theme}>
      {icon && icon.localFile && (
        <div className="icon-container">
          <SeriesBadge className="badge">
            <Image source={icon} alt_text={`${heading} icon`} />
          </SeriesBadge>
        </div>
      )}
      <div className="inner">
        <ComponentContent className="content-container">
          <H3 className="heading">{heading || name}</H3>
          <TextContent
            contentAlign="left"
            content={content || description}
            className="content"
          />
          {link && (
            <Link className="teaser-link" link={link} hasArrow theme={theme} />
          )}
        </ComponentContent>
      </div>
    </SeriesTeaser>
  )
}
