import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import { H3, H3Sizes } from '@styles/Typography'
import Range from '@elements/Range'
import BeerFormats from './BeerFormats'

export const BeerDetails = styled.div`
  max-width: ${tokens.get('maxWidth.base')};
  margin-left: auto;
  margin-right: auto;
  border-color: ${styledMap('theme', {
    light: tokens.color('white', '02'),
    default: tokens.color('text'),
  })};

  > .inner {
    flex: 1 1 auto;
  }

  .details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    ${media.lessThan('750px')`
      max-width: 22rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    `}
  }

  .detail-col {
    flex: 1 1 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${spacing(5)};

    ${media.greaterThan('750px')`
      flex-grow: 0;
      flex-basis: calc(50% - ${spacing(4)});
    `}

    ${media.greaterThan('m')`
      flex-basis: 18rem;
    `}
  }

  .detail---availability {
    max-width: 80%;
  }

  .heading {
    margin-bottom: ${spacing(2)};
  }

  .label {
    ${H3Sizes}
    margin-top: ${spacing()};
  }
`

export default ({
  details: { type, abv, availability, malt, hops },
  formats: {
    label: formatLabel,
    content: formatContent,
    links: formatLinks,
    sizes = [],
  },
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <BeerDetails theme={theme}>
      <div className="details">
        <div className="detail-col">
          <div className="detail">
            <H3 className="heading">{type.label}</H3>
            {type.value && <div className="label">{type.value}</div>}
          </div>
        </div>
        <div className="detail-col">
          <div className="detail detail---availability">
            <H3 className="heading">{availability.label}</H3>
            {availability.value && (
              <div
                className="label"
                dangerouslySetInnerHTML={{ __html: availability.value }}
              />
            )}
          </div>
          <div className="detail">
            <H3 className="heading">{abv.label}</H3>
            {abv.value && <div className="label">{`${abv.value}%`}</div>}
          </div>
        </div>
        <div className="detail-col">
          <H3 className="heading">{malt.label}</H3>
          <Range
            value={malt.value}
            labels={['Pale', 'Roasted']}
            gradientKey="malt"
          />
        </div>
        <div className="detail-col">
          <H3 className="heading">{hops.label}</H3>
          <Range
            value={hops.value}
            labels={['Mild', 'Hoppy']}
            gradientKey="hops"
          />
        </div>
      </div>
      <BeerFormats
        formatLabel={formatLabel}
        formatContent={formatContent}
        formatLinks={formatLinks}
        sizes={sizes}
      />
    </BeerDetails>
  )
}
