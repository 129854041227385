import React, { useContext } from 'react'
import { ThemeContext } from '@layout/Layout'
import ComponentContainer from '@layout/ComponentContainer'
import styled, { css } from 'styled-components'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import Image from '@elements/Image'
import { tokens, media, spacing, box } from '@tokens'

export const getBeerDimensions = ({ imageType, aspectRatio }) => {
  const scale = tokens.get(`beerImageScale`)[imageType]
  const maxHeight = 1000

  const height = maxHeight * scale
  const width = maxHeight * scale * aspectRatio

  return {
    height,
    width,
    scale,
  }
}

export const BeerFormatGallery = styled.div`
  position: relative;
  width: 100%;
`
export const BeerFormatGalleryInner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const BeerFormatImage = styled.div`
  position: relative;

  ${({ totalWidth, meta }) => {
    return css`
      flex: 0 1 ${(meta.width / totalWidth) * 100}%;
      max-width: ${(meta.width * 1 * 0.65) / 16}rem;
    `
  }};

  > figure {
    margin-left: -2.5%;
    margin-right: -2.5%;
  }

  &[class*='-box'] {
    /* 12px / 1008px */
    margin-bottom: -1.19047619%;
  }
`

export default ({
  order = ['small-can', 'large-can', 'small-bottle', 'large-bottle'],
  ...data
}) => {
  const { theme } = useContext(ThemeContext)
  const boxPriority = [
    'small-bottle-box',
    'large-bottle-box',
    'large-can-box',
    'small-can-box',
  ]

  const allImages = Object.keys(data)
    .map((key) => {
      return key.includes('Image') && data[key]
        ? [key.replace('Image', ''), data[key]]
        : false
    })
    .filter(Boolean)
  if (allImages.length < 2) {
    return null
  }

  const prioritySize = boxPriority.find((format) => {
    return allImages.some(([key, value]) => {
      return key === camelCase(format) && !!value
    })
  })

  const boxImage = allImages.find(([key]) => {
    return key.includes(camelCase(prioritySize))
  })

  const sortedImages = allImages
    .filter(([key]) => {
      return !key.includes('Box')
    })
    .sort(([keyA], [keyB]) => {
      return order.indexOf(kebabCase(keyA)) - order.indexOf(kebabCase(keyB))
    })

  const half = Math.floor(sortedImages.length / 2)
  const gallery = uniqWith(
    [
      ...sortedImages.slice(0, half),
      boxImage,
      ...sortedImages.slice(half, sortedImages.length),
    ],
    isEqual
  )

  const totalWidth = gallery
    .map(([key, image], index) => {
      const imageType = kebabCase(key)

      const { aspectRatio } = image?.localFile?.childImageSharp?.fluid
      const { width, height, scale } = getBeerDimensions({
        imageType,
        aspectRatio,
      })
      gallery[index].push({
        width,
        height,
        scale,
        imageType,
        aspectRatio,
      })
      return width
    })
    .reduce((total, value) => total + value)

  return (
    <ComponentContainer size="full">
      <BeerFormatGallery theme={theme}>
        <BeerFormatGalleryInner>
          {gallery.map(([key, image, meta]) => {
            const imageType = kebabCase(key)
            return (
              <BeerFormatImage
                key={key}
                imageType={imageType}
                className={`format-image format-image--${imageType}`}
                meta={meta}
                totalWidth={totalWidth}
              >
                <Image
                  source={image}
                  objectFit="contain"
                  objectPosition="50% 100%"
                />
              </BeerFormatImage>
            )
          })}
        </BeerFormatGalleryInner>
      </BeerFormatGallery>
    </ComponentContainer>
  )
}
