import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Image from '@elements/Image'
import { Link } from '@elements/Link'
import { H3 } from '@styles/Typography'
import ArrowDual from '@svgs/ArrowDual'
import Modal from '@components/Modal'
import { ThemeContext } from '@layout/Layout'

export const BeerFormats = styled.div`
  margin-top: ${spacing(5)};
  align-items: center;

  > .inner {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    max-width: ${tokens.get('maxWidth.tiny')};
  }

  &,
  .icons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    ${media.greaterThan('m')`
      flex-flow: row nowrap;
    `}
  }

  .detail-label {
    ${media.lessThan('s')`
      text-align: center;
    `}
  }

  .icons {
    justify-content: center;
    align-items: flex-end;
    margin-right: 0;
    margin-left: auto;

    ${media.lessThan('s')`
      justify-content: center;
      max-width: 20rem;
      margin-right: auto;
      margin-top: ${spacing(-5)};
    `}

    .image {
      ${styledMap('theme', {
        dark: css`
          mix-blend-mode: lighten;
        `,
        default: css`
          filter: invert();
          mix-blend-mode: multiply;
        `,
      })};
    }
  }

  .icon-label {
    margin-top: ${spacing(2)};
    text-align: center;

    span {
      display: block;
      font-size: 0.875rem;
    }
  }

  .content {
    max-width: 15.2em;
    margin-bottom: ${spacing(3)};
  }

  .size {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
    width: ${spacing(8)};

    ${media.lessThan('s')`
      margin-left: ${spacing(3)};
      margin-right: ${spacing(3)};
      margin-top: ${spacing(5)};
    `}

    ${media.greaterThan('s')`
      margin-left: ${spacing(8)};

      &:first-child {
        margin-left: 0;
      }
    `}

    &--cans-all,
    &--bottles-all {
      .image {
        width: 2.5rem;
      }
    }

    &--bottles-large-only,
    &--cans-large-only {
      .image {
        width: 1.5rem;
      }
    }

    &--bottles-small-only,
    &--cans-small-only {
      .image {
        width: 1.25rem;
      }
    }

    &--growlers {
      .image {
        width: 2.75rem;
      }
    }

    &--kegs {
      .image {
        width: 4.5rem;
      }
    }
  }
`

export default ({
  formatLabel,
  formatContent,
  formatLinks,
  sizes: { nodes: initialSizes },
}) => {
  const { theme } = useContext(ThemeContext)
  const sizes = initialSizes
    .map(({ slug, ...size }) => {
      let order
      if (slug.includes('kegs')) {
        order = 1
      } else if (slug.includes('growlers')) {
        order = 2
      } else if (slug.includes('bottles')) {
        order = 3
      } else if (slug.includes('cans')) {
        order = 4
      }

      return {
        ...size,
        slug,
        order,
      }
    })
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1
    })

  return (
    <BeerFormats theme={theme}>
      <div className="inner">
        <H3 className="detail-label">{formatLabel}</H3>
        <div className="icons">
          {sizes &&
            sizes.map(({ name, slug, contentSizeFormats }) => {
              const text = contentSizeFormats?.label || name.split(' ')[0]
              const className = `size--${slug}`
              const icon = contentSizeFormats?.icon || {}

              return (
                <div key={slug} className={`size ${className}`}>
                  <Image className="image" source={icon} />
                  {text && (
                    <div
                      className="icon-label"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </BeerFormats>
  )
}
