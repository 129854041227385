import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3 } from '@styles/Typography'
import { ReactComponent as MaltImage } from '@images/featured-malt.svg'
import { ReactComponent as HopsImage } from '@images/featured-hops.svg'
import { ThemeContext } from '@layout/Layout'

export const BeerIngredients = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: ${spacing(10)};

  ${media.greaterThan('s')`
    flex-flow: row nowrap;
  `}

  .ingredient {
    flex: 1 1 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 20rem;

    + .ingredient {
      margin-top: ${spacing(5)};
    }

    ${media.greaterThan('s')`
      flex: 0 1 19rem;

      + .ingredient {
        margin-top: 0;
        margin-left: ${spacing(6)};
      }
    `}
  }
  .ingredient-names {
    text-align: center;
  }

  .ingredient-name {
    line-height: ${tokens.get('type.lineHeight.meta')};

    &:not(:last-child)::after {
      content: ', ';
    }
  }

  svg {
    display: block;
    height: 10rem;
    width: auto;
    margin-bottom: ${spacing(3)};
  }

  .heading {
    margin-bottom: ${spacing(2)};
  }

  .ingredient-image {
    ${styledMap('theme', {
      dark: css`
        mix-blend-mode: lighten;
      `,
      default: css`
        filter: invert();
        mix-blend-mode: multiply;
      `,
    })};
  }
`

const BeerIngredient = ({ title, ingredientNames, image }) => {
  return (
    <div className="ingredient">
      <div className="ingredient-image">{image}</div>
      <H3 className="heading">{title}</H3>
      <div className="ingredient-names">
        {ingredientNames &&
          ingredientNames.map(({ name }) => {
            return (
              <span key={name} className="ingredient-name">
                {name}
              </span>
            )
          })}
      </div>
    </div>
  )
}

export default ({ malts, hops }) => {
  const ingredients = [
    {
      title: 'Featured Malts',
      ingredientNames: malts,
      image: <MaltImage />,
    },
    {
      title: 'Featured Hops',
      ingredientNames: hops,
      image: <HopsImage />,
    },
  ]
  const { theme } = useContext(ThemeContext)
  return (
    <BeerIngredients theme={theme}>
      {ingredients &&
        ingredients.map(({ title, ingredientNames, image }) => {
          return (
            <BeerIngredient
              key={title}
              title={title}
              ingredientNames={ingredientNames}
              image={image}
            />
          )
        })}
    </BeerIngredients>
  )
}
