import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'

export const SeriesBadge = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${tokens.brand('light')};

  ${styledMap('large', {
    true: css`
      width: 10rem;
      height: 10rem;

      img,
      svg {
        width: 100%;
      }
    `,
    default: css`
      width: 4rem;
      height: 4rem;

      img,
      svg {
        width: 56.25%;
      }
    `,
  })};

  img,
  svg {
    display: block;
    fill: ${tokens.color('black')};
  }
`
export default ({ className, children, large }) => {
  return (
    <SeriesBadge className={className} large={large}>
      {children}
    </SeriesBadge>
  )
}
