import React from 'react'
import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import { ComponentBase } from '@mixins/MixinsComponent'
import Image from '@elements/Image'

export const BeerAwards = styled.div`
  ${ComponentBase}
  ${box({
    spacing: 'top',
    multiplier: -0.5,
    type: 'margin',
  })};
  ${box({
    spacing: 'bottom',
    multiplier: 0,
    type: 'margin',
  })};
  
  width: 100%;
  
  > .inner {
    ${media.greaterThan('l')`
      margin-top: ${spacing(-2)};
      padding-left: 12rem;
    `}

    > .content {
      max-width: ${tokens.get('maxWidth.narrow')};
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      
      ${media.greaterThan('l')`
          margin-right: 0;
      `}
    }
  }


  .logo-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing(4)};

    > * {
        flex: 0 0 auto;
    }
  }
`
export const BeerAward = styled.div`
    opacity: 0.9;
    ${({aspectRatio}) => css`
        width: ${aspectRatio * 80 /16}rem;
        
        ${media.greaterThan('s')`
            width: ${aspectRatio * 112 /16}rem;
      `}
    `}
`

export default ({ className = '', size = 'wide', awards }) => {
    return (
    <BeerAwards className={className} size={size}>
      <div className="inner">
        <div className="content" contentAlignment='right'>
            <div className='logo-grid'>
                {awards.map(image => {
                    const { aspectRatio } = image?.localFile?.childImageSharp?.fluid
                    return (
                        <BeerAward key={image.localFile.publicURL} aspectRatio={aspectRatio}>
                            <Image
                                style={{width: '100%'}}
                                source={image}
                                objectFit="contain"
                                objectPosition="50% 100%"
                            />
                        </BeerAward>
                    )
                })}
            </div>
        </div>
      </div>
    </BeerAwards>
  )
}
