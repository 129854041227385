import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'

export const Range = styled.div`
  position: relative;
  width: 100%;
  height: ${spacing(3)};
  border-radius: 0.75rem;
  width: 100%;
  ${styledMap('gradientKey', {
    malt: css`
      background: linear-gradient(
        to right,
        ${tokens.color(`gradient.malt.left`)},
        ${tokens.color('gradient.malt.right')}
      );
    `,
    hops: css`
      background: linear-gradient(
        to right,
        ${tokens.color(`gradient.hops.left`)},
        ${tokens.color('gradient.hops.right')}
      );
    `,
    default: '',
  })};

  .dot {
    position: absolute;
    top: 50%;
    height: ${spacing(2)};
    width: ${spacing(2)};
    margin-top: ${spacing(-1)};
    border-radius: 100%;
    background-color: ${tokens.color('white')};
  }
`

export default ({ labels, value, gradientKey }) => {
  return (
    <>
      <Range gradientKey={gradientKey}>
        {value && (
          <div className="range">
            <div className="dot" style={{ left: `${value}%` }} />
          </div>
        )}
      </Range>
      {labels &&
        labels.map(label => {
          return (
            <span key={label} className="label">
              {label}
            </span>
          )
        })}
    </>
  )
}
