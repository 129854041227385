import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Image from '@elements/Image'
import { ThemeContext } from '@layout/Layout'
import { H3 } from '@styles/Typography'
import { MagicItem } from '@hooks/useMagic'
import { motion, useAnimation } from 'framer-motion'

export const BeerGlassImage = styled.div`
  position: relative;
  width: 100%;

  ${media.greaterThan('s')`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 16.75% auto auto;
    grid-column-gap: ${spacing(8)};

  `}

  ${media.greaterThan('m')`
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${spacing(6)};
    grid-template-rows: 16.75% 21% auto;
  `}

  .image-container {
    position: relative;
    z-index: ${tokens.z('content')};
    max-width: 10.25rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('s')`
      grid-column: 1 / 3;
      grid-row: 1 / -1;
      max-width: ${({ ratio }) => {
        switch (true) {
          case ratio < 0.32:
            return '13rem'
          default:
            return 'none'
        }
      }};


   `}

    ${media.greaterThan('m')`
      grid-column: 1 / 7;
      grid-column: 5 / 9;
    `}
  }

  .info-boxes {
    position: relative;
    z-index: ${tokens.z('base')};

    ${media.lessThan('m')`
      grid-column: 3 / -1;
      grid-row: 2 / -1;
    `}

    ${media.greaterThan('m')`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 16.75% auto auto;
      grid-column-gap: ${spacing(6)};
    `}
  }

  .info-box {
    max-width: 20rem;

    ${media.lessThan('s')`
      margin-left: auto;
      margin-right: auto;
      margin-top: ${spacing(5)};
    `}

    ${media.greaterThan('m')`
      max-width: 13.5rem;
    `}

    .heading {
      position: relative;
      margin-bottom: ${spacing(2)};

      &::before {
        ${media.greaterThan('s')`
          position: absolute;
          top: 50%;
          content: '';
          display: block;
          height: 1px;
          width: 10rem;
          transform: translateY(-50%);
          right: 100%;
          margin-right: ${spacing(2)};
          background-color: ${styledMap('theme', {
            dark: tokens.color('white', '04'),
            default: tokens.color('black', '04'),
          })};
        `}

        ${media.greaterThan('l')`
          width: 16rem;
        `}
      }
    }

    &.taste {
      grid-column: 7 / -1;
      grid-row: 1 / -1;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;

      ${media.greaterThan('m')`
        grid-column-start: 9;
      `}

      ${media.greaterThan('l')`
        grid-column-start: 10;
      `}
    }
  }
`

export default ({ glassImage, tasteDescription }) => {
  const { theme } = useContext(ThemeContext)
  const [isLoaded, setLoaded] = useState(false)
  const ratio = glassImage?.localFile?.childImageSharp?.fluid?.aspectRatio

  const handleLoad = () => {
    setLoaded(true)
  }
  return (
    <BeerGlassImage theme={theme} ratio={ratio}>
      <motion.div
        className="image-container"
        style={{ opacity: isLoaded ? 1 : 0 }}
        transition={{ duration: 0.1 }}
      >
        <MagicItem className="" type="fadeUp">
          <Image source={glassImage} onLoad={handleLoad} />
        </MagicItem>
      </motion.div>
      {tasteDescription && (
        <div className="info-boxes">
          {tasteDescription && (
            <div className="taste info-box">
              <H3 className="heading">Taste</H3>
              <div className="content">{tasteDescription}</div>
            </div>
          )}
        </div>
      )}
    </BeerGlassImage>
  )
}
